import React from 'react';
import Layout from "../layout";

const AnjaFest3 = () => {
  return (
    <Layout
    seo={{
        path: "/anja-fest-3",
        title: "Anja Fest 3",
        description: "Join ANJA FEST III Block Party at Highland Park's premier cannabis dispensary! Experience live music, food vendors, activities, and community vibes. Free entry, 21+ only. RSVP now!",
    }}
    >
        <div className='px-6 py-12'>
            <div className='cContainer'>
                <div className="py-4">
                    <h2>Saturday, April 19</h2>
                    <h1>ANJA FEST III: Block Party</h1>

                    <div className='flex flex-row items-center justify-start gap-x-4'>
                    <div className='flex flex-row items-center justify-start'>
                        <div className='w-[48px] h-[48px] mr-2'>
                            <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 8h12v8H6V8zM4 18V6h16v12H4zm5.5-6c.863 0 1.5-.637 1.5-1.5S10.363 9 9.5 9 8 9.637 8 10.5 8.636 12 9.5 12zm4.5-2h3v1.429h-3V10zm.75 2.857H17v1.429h-2.25v-1.429zm-2.178 1.812c0-.982-1.256-1.99-2.786-1.99-1.529 0-2.786 1.008-2.786 1.99V15h5.572v-.331z" fill="#010101"></path></svg>
                        </div>
                        
                        <h6 className='bg-purple text-white rounded-xl w-fit p-2'>
                            Ages 21+
                        </h6>
                    </div>
                    
                    <div className='flex flex-row items-center justify-start'>
                        <div className='w-[48px] h-[48px] mr-2'>
                        <svg viewBox="0 0 24 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.001 8h1.991v4h2.64v2h-4.63V8z" fill="#010101"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M17.676 10.053A6.011 6.011 0 0012 6c-3.308 0-6 2.692-6 6a6.007 6.007 0 006.888 5.934l1.055 1.828a8 8 0 115.879-9.45l-2.146-.26zm.323 2.053a5.963 5.963 0 01-.453 2.183l1.687 1.134a7.959 7.959 0 00.76-3.075l-1.994-.242zm-1.517 3.878l1.674 1.125a8.035 8.035 0 01-2.328 1.917l-1.002-1.734a6.04 6.04 0 001.656-1.308z" fill="#010101"></path></svg>
                        </div>
                        
                        <h6 className='bg-purple text-white rounded-xl w-fit p-2'>
                        Event lasts 6 hours
                        </h6>
                    </div>
                    </div>
                    
                </div>
                <div className='rte mb-4'>
                    <p>ANJA Fest is back and we are taking over the block! Music, Food, Art, Activities, Prizes & Giveaways, and more! 21+ to enter.</p>
                </div>
                <div className='mb-8' style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe
                    src="https://player.vimeo.com/video/929199314?h=3a7c1d1778&autoplay=0&title=0&byline=0&portrait=0"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    title="Vimeo Video"
                    />
                </div>
                
                <div className="rte mb-8">
                   
                    <h4>ANJA’s annual 420 Celebration is back and we are taking over the block!</h4>
                    <p>ANJA FEST was a hit last year with over 500 attendees!</p>
                    <a href='https://www.eventbrite.com/e/anja-fest-iii-block-party-tickets-1261365751919?aff=erelexpmlt' className="button button--primary button--large mb-4">RSVP Now</a>
                    <p>Join us for our 3rd annual ANJA FEST, where we will be celebrating all things Cannabis with a fun filled day of Music, Art, Activities, Food Trucks, Contests, Prizes, and More!</p>
                    <p className='font-bold'>Must be 21+ to enter.</p>
                    <p>Register now to be entered for a chance to Win a <span className='font-bold'>FREE Ounce of Flower at the event!</span> There will be one winner randomly selected from those who register online at the big 4:20 celebration - don't miss it!</p>
                    <p>We'll continue to keep everyone updated with the latest ANJA Fest news through our emails, Instagram, and this events page. And as always, make sure to check out our Daily Deals, events, and discounts at: <a className="text-purple !underline" href="https://www.getanja.com">https://www.getanja.com</a></p>
                    
        
                    <p className='font-bold'>***More Details coming soon! ***</p>


                    <h4>Interested in being a Vendor?</h4>
                    <p>Awesome! Please fill out the vendor form and we'll be in touch to discuss details: <a className="text-purple !underline" href="https://forms.gle/X5uw1fCmjyUrMt6B7">https://forms.gle/X5uw1fCmjyUrMt6B7</a></p>
                    
                </div>

                
                <div className="flex flex-col md:grid grid-cols-4 items-center">
                    <div className='col-span-4 sm:col-span-2 -mx-6 md:mx-0 mb-0 h-[300px] sm:h-[420px] md:h-full w-full md:order-2'>
                        <iframe title="ANJA Google Map Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d758.4545365736411!2d-74.41193227074523!3d40.50140421847246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c7d475e16b2b%3A0x315065177c51eb46!2s225%20Woodbridge%20Ave%2C%20Highland%20Park%2C%20NJ%2008904!5e0!3m2!1sen!2sus!4v1677266615088!5m2!1sen!2sus" className="border-0 w-full h-full" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
        
                    <div className='col-span-4 sm:col-span-2 text-left mt-8 md:mt-0 learn-more md:pr-16'>
                        <div className='rounded-2xl shadow-2xl px-6 py-8 flex flex-col items-center md:items-start'>
                                <div className='rte mb-6'>
                                    <h4>Location</h4>
                                    <p>Visit our Highland Park, NJ recreational cannabis dispensary located at 225 Woodbridge Ave, Highland Park, NJ 08904.</p>
                                    <br/>
                                    <h4>Date and Time</h4>
                                    <p>Saturday, April 19 · 1 - 7pm EDT</p>
                                </div>
                               
                                <a href='https://www.eventbrite.com/e/anja-fest-iii-block-party-tickets-1261365751919?aff=erelexpmlt' className="button button--primary button--large">RSVP Now</a>
                                
                        </div>
                    </div>
                </div>


            </div>
        </div>
       
    </Layout>
  )
}

export default AnjaFest3